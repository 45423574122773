<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">

                    <div class="popup-title">
                        <h1 class="title is-1">{{ $t('admin.report.download_title') }}</h1>
                        <p>{{ $t('admin.report.download_description') }}</p>

                        <div class="popup-close" @click.prevent="destroy">
                            <i class="icon">close</i>
                        </div>
                    </div>

                    <g-form :form="form">
                        <div class="popup-body popup-rows">

                            <div class="columns">
                                <div class="column is-one-quarter"><label>{{ $t('admin.report.template') }}</label>
                                </div>
                                <div class="column">
                                    <g-select name="templateId"
                                              :label="$t('admin.report.template')"
                                              :options="reportTemplates"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-quarter"><label>{{ $t('admin.report.period') }}</label></div>
                                <div class="column">
                                    <g-datepicker
                                        :label="$t('admin.report.period')"
                                        name="startDate"
                                        :end-date="'endDate'"
                                    />
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-quarter"><label>{{ $t('admin.report.format') }}</label></div>
                                <div class="column">
                                    <g-select name="format"
                                              :label="$t('admin.report.format')"
                                              :options="formatOptions"/>
                                    <br /><br /><br /><br /><br /><!-- We need some spacing for the date picker -->
                                </div>
                            </div>
                        </div>

                        <div class="popup-footer">
                            <a @click.prevent="destroy" href="#" role="button">{{ $t('confirmation_dialog.deny') }}</a>
                            <g-button @click.prevent="downloadReport" :loading="downloading">
                                {{ $t('admin.report.download') }}
                            </g-button>
                        </div>
                    </g-form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        company_id: {
            type: [Number, String],
            required: true
        }
    },
    data() {
        return {
            downloading: false,
            reportTemplates: [
                {name: this.$t('admin.report.templates.own_brand'), value: 'own_brand'},
                {name: this.$t('admin.report.templates.collaboration'), value: 'collaboration'},
                {name: this.$t('admin.report.templates.cumulative'), value: 'cumulative'},
                {name: this.$t('admin.report.templates.overview'), value: 'overview_report'}
            ],
            formatOptions: [
                {name: 'PDF', value: 'pdf'},
                {name: 'CSV', value: 'csv'}
            ],
            form: new Form({
                templateId: 'own_brand',
                startDate: this.getFirstDayLastMonth(),
                endDate: this.getLastDayLastMonth(),
                format: 'pdf'
            })
        }
    },
    methods: {
        getFirstDayLastMonth() {
            const date = new Date();
            date.setDate(1);
            date.setMonth(date.getMonth() - 1);
            date.setHours(0, 0, 0, 0);
            return date.toISOString();
        },
        getLastDayLastMonth() {
            const date = new Date();
            date.setDate(0);
            date.setHours(23, 59, 59, 999);
            return date.toISOString();
        },
        async downloadReport() {
            this.downloading = true;

            try {
                // Download met axios
                const response = await axios({
                    method: 'get',
                    url: `/api/admin/company/${this.company_id}/report/${this.form.templateId}`,
                    params: {
                        period: {
                            start: this.form.startDate,
                            end: this.form.endDate
                        },
                        format: this.form.format,
                        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                    },
                    responseType: 'blob',
                    paramsSerializer: {
                        indexes: true, // use brackets with indexes
                    }
                });

                const filename = this.generateFilename(this.form.templateId, this.form.startDate, this.form.endDate, this.form.format);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();

                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);

                this.destroy();
            } catch (error) {
                console.error('Download error:', error);
                this.$buefy.toast.open({
                    message: this.$t('admin.report.download_error'),
                    type: 'is-danger'
                });
            } finally {
                this.downloading = false;
            }
        },
        generateFilename(templateId, startDate, endDate, format) {
            return `${this.company_id}-${templateId}-${startDate}-${endDate}.${format}`;
        }
    }
}
</script>
