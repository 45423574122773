// api calls
import {giftcardsApi} from '../../api/Giftcards'

// state
const state = {
    giftcard:               null,
    giftcards:              [],
    redeem_success_message: false,
    issue_success_message:  false,
    last_giftcard_code:     null,
}

// getters
const getters = {
    giftcard:               state => state.giftcard,
    giftcards:              state => state.giftcards,
    redeem_success_message: state => state.redeem_success_message,
    issue_success_message:  state => state.issue_success_message,
    last_giftcard_code:     state => state.last_giftcard_code,
}

// actions
const actions = {
    getAllGiftcards ({commit}, data) {
        return new Promise((resolve, reject) => {
            giftcardsApi.getAllGiftcards().then(({data}) => {
                console.log('Giftcards', data)
            })
        })
    },

    /* Search Giftcard by code */
    searchGiftcard ({commit}, data) {
        return new Promise((resolve, reject) => {
            giftcardsApi.searchGiftcard(data.code)
                .then(({data}) => {
                    commit('setGiftcard', data.giftcard)
                    resolve(data)
                })
        })
    },

    /* Reset Giftcard */
    resetGiftcard ({commit}) {
        commit('setGiftcard', null)
    },

    /* Redeem Giftcard */
    redeemGiftcard ({commit}, data) {
        commit('setLastGiftcardCode', data.giftcard_code)
        return new Promise((resolve, reject) => {
            giftcardsApi.redeemGiftcard(
                data.giftcard_code,
                data.amount,
                data.currency,
                data.capture,
                data.location_id,
                data.comment,
            )
                .then(({data}) => {
                    commit('setGiftcard', null)
                    commit('setRedeemSuccessMessage', true)
                    resolve(data)
                })
                .catch(error => {
                    reject(error.response)
                })
        })
    },

    /* Set Redeem Success Message */
    setRedeemSuccessMessage ({commit}, status) {
        commit('setRedeemSuccessMessage', status)
    },

    /* Reset last Giftcard code */
    resetLastGiftcardCode ({commit}) {
        commit('setLastGiftcardCode', null)
    },

    /* Issue Giftcard */
    issueGiftcard ({commit}, data) {
        commit('setLastGiftcardCode', data.giftcard_code)
        return new Promise((resolve, reject) => {
            let package_id = null;

            if(data.package_id) {
                package_id = data.package_id
            }

            giftcardsApi.issueGiftcard(
                data.giftcard_code,
                data.amount,
                data.currency,
                data.promotional,
                data.location_id,
                package_id,
                data.comment,
            )
                .then(({data}) => {
                    commit('setGiftcard', null)
                    commit('setIssueSuccessMessage', true)
                    resolve(data)
                })
                .catch(error => {
                    reject(error.response)
                })
        })
    },

    /* Set Issue Success Message */
    setIssueSuccessMessage ({commit}, status) {
        commit('setIssueSuccessMessage', status)
    },
}

// mutations
const mutations = {
    setGiftcard (state, giftcard) {
        state.giftcard = giftcard
    },
    setRedeemSuccessMessage (state, status) {
        state.redeem_success_message = status
    },
    setLastGiftcardCode (state, code) {
        state.last_giftcard_code = code
    },
    setIssueSuccessMessage (state, status) {
        state.issue_success_message = status
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
