<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">
                    <div class="popup-title">
                        <h1 class="title is-1" v-if="this.status === 'blocked'">Cadeaubon vrijgeven</h1>
                        <h1 class="title is-1" v-else>Cadeaubon blokkeren</h1>

                        <div class="popup-close" @click.prevent="destroy">
                            <i class="icon">close</i>
                        </div>
                    </div>

                    <g-form :form="form"
                            :action="`/api/admin/giftcard/${this.id}`"
                            method="patch"
                            @success="this.destroy">

                        <div class="popup-body" v-if="this.status === 'blocked'">
                            Weet je zeker dat je deze cadeaubon weer wilt vrijgeven?
                        </div>
                        <div class="popup-body" v-else>
                            Weet je zeker dat je deze cadeaubon wilt blokkeren?

                            <div class="field mt-4">
                                <textarea class="textarea" rows="2"
                                          placeholder="Geef de reden van blokkeren op (optioneel)"
                                          v-model="form.block_reason"/>
                            </div>
                        </div>

                        <div class="popup-footer">
                            <a @click.prevent="destroy" href="#" role="button">Annuleren</a>
                            <g-button v-if="this.status === 'blocked'">Vrijgeven</g-button>
                            <g-button v-else>Blokkeren</g-button>
                        </div>

                    </g-form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    props: {
        id: Number,
        status: String,
    },

    data() {
        const formData = {
            block: this.status === 'blocked' ? 0 : 1,
            block_reason: null,
        }

        return {
            form: new Form(formData)
        }
    },
}
</script>
