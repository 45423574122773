<template>
    <ModalLayout
        visible
        @destroy="close"
        @success="submitForm"
        :confirmation-modal="true"
        :success-loading="loading"
        :tabs="tabItems"
        :tabActive="activeTab"
        @tabSelected="(tab) => activeTab = tab"
    >
        <template v-slot:title>
            {{ $t('digital.email.title') }}
        </template>

        <template v-slot:settingArea>
            <p>
                {{ $t('digital.email.subtitle') }}
            </p>

            <g-form :form="form" class="form-container" ref="formRef" :action="formEndpoint"
                    @loading-state="setLoadingState" @fail="setActiveStepForErrors" @success="formSuccess">

                <accordeon-item
                    :title="$t('digital.email.layout')"
                    :top-title="$t('accordeon.step', [1])"
                    :active="activeStep === 1"
                    @select="selectStep(1)"
                >
                    <div class="is-flex is-flex-direction-column is-justify-content-space-between form-min-height">
                        <div>
                            <label class="group-label">
                                {{ $t('digital.email.order') }}
                            </label>
                            <g-input name="order_reference" :label="$t('digital.email.order')" disabled readonly/>

                            <label class="group-label">{{ $t('digital.email.sender') }}</label>
                            <div class="field-body">
                                <g-input name="sender_name" :label="$t('digital.email.name')"/>
                                <g-input name="sender_email" :label="$t('digital.email.email')"/>
                            </div>

                            <label class="group-label">
                                {{ $t('digital.email.subject') }}
                            </label>
                            <g-input name="subject" :label="$t('digital.email.subject')"/>

                            <label class="group-label">
                                {{ $t('digital.email.message') }}
                                <small>
                                    {{ $t('digital.email.message_notice') }}
                                </small>
                            </label>
                            <g-input type="textarea" name="message" :label="$t('digital.email.message_contents')"/>
                        </div>

                        <div>
                            <g-button @click.prevent="activeStep++" :is-rounded="false" class="is-pulled-right">
                                {{ $t('digital.create.next_step') }}
                            </g-button>
                        </div>
                    </div>

                </accordeon-item>

                <accordeon-item
                    :title="$t('digital.email.ship')"
                    :top-title="$t('accordeon.step', [2])"
                    :active="activeStep === 2"
                    @select="selectStep(2)"
                >
                    <div class="is-flex is-flex-direction-column is-justify-content-space-between form-min-height">
                        <div>
                            <label class="group-label">
                                {{ $t('digital.email.emails') }}
                                <small>{{ $t('digital.email.emails_subtitle') }}</small>
                            </label>
                            <g-input :label="$t('digital.email.emails')" name="recipients" type="textarea"/>

                            <div class="notification is-light">
                                <div>
                                    {{ $t('digital.delivery_form.email_check', {
                                    email: $store.getters['user/email']
                                }) }}
                                </div>
                                <button @click.prevent="sendTestMail" class="button is-white"
                                        :class="{ 'is-loading' : sendTestMailLoading }">
                                    {{ $t('digital.delivery_form.send') }}
                                </button>
                            </div>
                        </div>
                    </div>

                </accordeon-item>

            </g-form>
        </template>

        <template v-slot:previewArea>
            <tabbed-view @tabsInitialized="(tabs) => tabItems = tabs" ref="tabbedView">
                <tabbed-view-item
                    :title="$t('digital.email.email_tab')"
                    :active="activeTab === 0"
                >
                    <email-view-item
                        :sender-name="debouncedSender"
                        :sender-email="debouncedSenderMail"
                        :subject="debouncedSubject"
                        :attachments="['CADEAUBON-100-00000001-00264424-0.pdf']"
                    >
                        <template v-slot:header v-if="logo">
                            <img alt="Company logo" :src="logo" />
                        </template>

                        <template v-slot:body>{{ debouncedEmailBody }}</template>
                    </email-view-item>
                </tabbed-view-item>

                <tabbed-view-item
                    :title="$t('digital.email.gift_card')"
                    :active="activeTab === 1"
                    blueprint
                >
                    <div class="preview-container">
                        <giftcard-preview
                            :title="previewData.title"
                            :value="previewData.value"
                            :card-side="previewCardSide"
                            :message="previewData.message"
                            hide-title
                            hide-switch-buttons/>
                        <div class="controls">
                            <a href="#" @click.prevent="previewCardSide = 'front'"
                               :class="{'is-active': previewCardSide === 'front'}">
                                {{ $t('digital.create.front') }}
                            </a>
                            <a href="#" @click.prevent="previewCardSide = 'back'"
                               :class="{'is-active': previewCardSide === 'back'}">
                                {{ $t('digital.create.back') }}
                            </a>
                        </div>
                    </div>
                </tabbed-view-item>

                <tabbed-view-item
                    :title="$t('digital.email.addresses')"
                    :active="activeTab === 2"
                    blueprint
                >
                    <template v-slot:header>
                        <strong>{{ $t('digital.email.emails_for_shipment') }}</strong>
                    </template>

                    <template v-slot:postheader>
                        <ul class="recipients">
                            <li v-for="(recipient, index) in debouncedAndFormattedRecipients" :key="index" v-if="recipient !== ''">
                                {{ recipient }}
                            </li>
                        </ul>
                    </template>

                    <line-table :title="$t('digital.create.order_total')">
                        <template v-slot:body>
                            <tr>
                                <td class="minimum-width">{{ order.quantity }}x</td>
                                <td>{{ $t('digital.email.shipment_per_email') }}</td>
                                <td class="minimum-width">{{ (0 * order.quantity) / 100 | currency }}</td>
                            </tr>
                        </template>
                        <template v-slot:footer>
                            <tr>
                                <td colspan="2" class="has-text-right">{{ $t('digital.create.total_excl_vat') }}</td>
                                <td class="minimum-width">{{ (0 * order.quantity) / 100 | currency }}</td>
                            </tr>
                        </template>
                    </line-table>
                </tabbed-view-item>
            </tabbed-view>
        </template>
    </ModalLayout>
</template>

<script>
import ModalLayout from "../../ModalLayout.vue";
import Accordeon from "../../Accordeon.vue";
import AccordeonItem from "../../AccordeonItem.vue";
import TabbedView from "../../TabbedView.vue";
import TabbedViewItem from "../../TabbedViewItem.vue";
import {mapGetters} from "vuex";
import TagsInput from "@johmun/vue-tags-input";
import LineTable from "../../LineTable.vue";
import GiftcardPreview from "../../../GiftcardPreview.vue";
import debounce from "lodash/debounce";
import posthog from "posthog-js";
import EmailViewItem from "./EmailViewItem.vue";

export default {
    name: 'DigitalOrderEmail',

    components: {
        EmailViewItem,
        GiftcardPreview,
        LineTable,
        TagsInput,
        TabbedViewItem,
        TabbedView,
        AccordeonItem,
        Accordeon,
        ModalLayout
    },

    props: {
        order: {
            type: Object,
            required: true,
        },
        logo: {
            type: String|null,
            required: true,
        }
    },

    computed: {
        ...mapGetters({
            packages: 'packages/all',
            locations: 'locations/allIssuePointSelect',
            reportingTags: 'promotionalOrders/getReportingTags',
            objectPrice: 'promotionalOrders/getPrice',
        }),

        packageOptions() {
            return this.packages.map((option) => {
                return {
                    name: option.title,
                    value: option.id
                }
            });
        },

        formEndpoint () {
            return `/api/company/${this.$store.getters['user/activeCompanyId']}/promotional/${this.order.id}/delivery`
        },
    },

    data() {
        return {
            loading: false,
            activeStep: 1,
            debouncedSubject: '',
            debouncedSender: '',
            debouncedSenderMail: '',
            debouncedEmailBody: '',
            debouncedAndFormattedRecipients: [],
            hasHadElegantSwitchOverToAddresses: false,
            sendTestMailLoading: false,
            activeTab: 0,
            tabItems: [],
            previewCardSide: 'front',
            previewData: {
                title: null,
                value: 4500,
                message: '',
            },
            fieldStepMap: {
                1: [
                    'order_reference',
                    'sender_name',
                    'sender_email',
                    'subject',
                    'message',
                ],
                2: [
                    'recipients'
                ],
            },
            form: new Form({
                order_reference: '',
                sender_name: '',
                sender_email: '',
                subject: '',
                message: '',
                recipients: '',
                accept_conditions: false,
            }),
        }
    },
    watch: {
        'form.subject': {
            handler: debounce(function () {
                this.debouncedSubject = this.form.subject;
            }, 500),
        },
        'form.sender_name': {
            handler: debounce(function () {
                this.debouncedSender = this.form.sender_name;
            }, 500),
        },
        'form.sender_email': {
            handler: debounce(function () {
                this.debouncedSenderMail = this.form.sender_email;
            }, 500),
        },
        'form.message': {
            handler: debounce(function () {
                this.debouncedEmailBody = this.form.message;
            }, 500),
            deep: true,
        },
        'form.recipients': function() {
            this.debounceUpdateRecipients();
        },
        'activeStep': function () {
            // When the user switches to the shipment step, we elegantly switch over to the addresses tab (the first time)
            // TODO: Fix marker bug not sliding over to the 2nd tab
            if (this.hasHadElegantSwitchOverToAddresses === false) {
                this.hasHadElegantSwitchOverToAddresses = true;
                this.activeTab = 2;
            }
        },
        form: {
            // Update preview data
            handler: debounce(function () {
                this.previewData.value = 4500;
                this.previewData.message = this.order.message || '';

                if (this.form.value_type === 'amount') {
                    this.previewData.title = undefined;
                } else {
                    const gcPackage = this.packageOptions.find(option => option.value === this.form.package_id);
                    this.previewData.title = this.form.package_id ? gcPackage.name : null;
                }
            }, 100),
            deep: true,
        },
    },

    created() {
        this.debounceUpdateRecipients = debounce(() => {
            this.debouncedAndFormattedRecipients = this.form.recipients.split('\n');
        }, 500);

        this.form.order_reference = this.$t('digital.quantity_worth', {
            quantity: this.$tc('digital.gift_card_plural', this.order.quantity, {amount: this.order.quantity}),
            price: this.$options.filters.currency(this.order.amount / 100)
        })

        this.$store.dispatch('packages/loadAll')
        this.$store.dispatch('locations/loadAll')

        this.form.sender_email = this.$store.getters['user/user'].email
        this.debouncedSenderMail = this.form.sender_email

        this.form.sender_name = this.$store.getters['user/user'].first_name
        this.debouncedSender = this.form.sender_name
    },

    methods: {
        sendTestMail () {
            this.testLoading = true

            this.form.post(this.formEndpoint + 'Test')
                .then(() => {
                    this.$buefy.toast.open({
                        message: this.$t('digital.delivery_form.test_mail_sent'),
                        type:    'is-success'
                    })
                })
                .catch(() => {
                    this.$buefy.toast.open({
                        message: this.$t('digital.delivery_form.test_mail_failed'),
                        type:    'is-danger'
                    })
                    this.activeStep = 1
                })
                .finally(() => {
                    this.testLoading = false
                })
        },

        close() {
            this.$emit('destroy');
        },

        selectStep(step) {
            if(this.activeStep === step) {
                step = step + 1;
            }

            if(step >= 3) {
                step = 1;
            }

            this.activeStep = step;
        },

        setLoadingState(state) {
            this.loading = state;
        },

        setActiveStepForErrors(data) {
            // If data object does not contain an errors object, return
            if (!data.errors) {
                return;
            }

            let formErrorFields = [];

            // Loop through the errors object, it has the format of {field: [errors]}
            for (const field in data.errors) {
                // If the field is not in the form object, continue
                if (!this.form.hasOwnProperty(field)) {
                    continue;
                }

                // Add the field to the formErrorFields array
                formErrorFields.push(field);
            }

            let stepsWithErrors = [];

            // Loop through the fieldStepMap object
            for (const step in this.fieldStepMap) {
                // Loop through the fields in the fieldStepMap object
                for (const field of this.fieldStepMap[step]) {
                    // If the field is in the formErrorFields array, add the step to the stepsWithErrors array
                    if (formErrorFields.includes(field)) {
                        stepsWithErrors.push(parseInt(step));
                        break;
                    }
                }
            }

            // If the stepsWithErrors array is not empty, set the activeStep to the lowest step
            if (stepsWithErrors.length > 0) {
                this.activeStep = Math.min(...stepsWithErrors);
            }
        },

        submitForm() {
            this.$refs.formRef.submit()
        },

        formSuccess(response) {
            this.$store
                .dispatch('promotionalOrders/add', {
                    object: response.data
                })
                .then(() => {
                    this.$emit('mail-sent')
                    this.destroy()

                    this.$buefy.toast.open({
                        message: this.$t('digital.email.toast'),
                        type: 'is-success',
                        duration: 5000,
                    })

                    posthog.capture(
                        'created_digital_gift_cards',
                        {
                            $set_once: { has_created_digital_gift_cards: true },
                        }
                    )
                })
        },
    }
}
</script>

<style scoped lang="scss">
.form-min-height {
    min-height: 100%;
}

.form-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
}

ul.recipients {
    list-style: none;
    margin: 0;

    li {
        padding: 0.5rem 1rem 0.6rem;
        background-color: #f7f9fb;
        border-bottom: 0.063rem solid #efefef;
    }
}

input.disabled {
    opacity: 0.5;
}

.preview-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem 0;
    max-width: 65%;
    align-self: center;

    @media (min-height: 850px) {
        max-width: 75%;
        margin-bottom: 2rem;
    }

    .controls {
        display: flex;
        justify-content: space-between;

        a {
            color: #1F1F1F;
            text-decoration: underline;

            &.is-active {
                cursor: default;
                font-weight: bold;
                text-decoration: none;
            }
        }
    }

    ::v-deep {
        canvas {
            max-width: 100% !important;
            height: auto !important;
            box-shadow: 0 0.688rem 3.375rem 0 rgba(0, 0, 0, 0.10);
            border-radius: 0.188rem;
            background: #FFFFFF;
        }
    }
}
</style>
