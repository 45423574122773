<template>
    <tab-content title="Prijzen">
        <g-form
            v-if="form"
            :form="form"
            :action="`/api/admin/company/${this.$route.params.company_id}/price`"
            :before="beforeSubmit"
            message="De prijzen zijn opgeslagen."
            method="post"
            @loading-state="(state) => isLoading = state"
        >
            <div v-for="(type_settings, type) in fields" class="columns column-form is-desktop">
                <div class="column is-3-desktop is-2-widescreen">
                    <div class="tab-subheader">{{ type_settings.section }}</div>
                </div>
                <div class="column is-7-widescreen">
                    <template v-for="option in type_settings.options">
                        <div class="field-header">
                            {{ option.title }}
                            <small v-if="option.description" class="has-text-grey is-block">{{ option.description }}</small>
                        </div>
                        <div class="field-body">
                            <template v-for="(field) in option.fields">
                                <g-input
                                    v-if="['number', 'percentage'].includes(field.type)"
                                    :name="`${type}[${field.reference_id ?? ''}][${field.name}]`"
                                    :label="fieldLabel(field)"
                                    type="number"
                                />
                                <g-format
                                    v-else
                                    :name="`${type}[${field.reference_id ?? ''}][${field.name}]`"
                                    :label="fieldLabel(field)"
                                    type="currency"
                                />
                            </template>
                        </div>
                    </template>
                </div>
            </div>

            <div class="columns">
                <div class="column is-offset-2">
                    <g-button type="submit" :loading="isLoading">Opslaan</g-button>
                </div>
            </div>
        </g-form>
    </tab-content>
</template>

<script>
import {formatCurrencyInput} from "../../../utils/Consts";
import TabContent from "../../../components/TabView/TabContent.vue";

export default {
    name: "Pricing",
    components: {TabContent},
    data() {
        return {
            isLoading: false,
            fields: [],
            form: null,
        }
    },
    async beforeMount() {
        await axios.get(`/api/admin/company/${this.$route.params.company_id}/price`)
            .then(({data}) => {
                this.fields = data;
                let formFields = {};

                for (const type in this.fields) {
                    this.fields[type].options.forEach(option => {
                        option.fields.forEach((field) => {
                            let fieldValue = field.value ?? null;

                            if (fieldValue !== null && field.type === 'currency') {
                                fieldValue = this.$options.filters.currency(fieldValue / 100);
                            }

                            formFields[type + '[' + (field.reference_id ?? '') + ']' + '[' + field.name + ']'] = fieldValue;
                        })
                    })
                }

                this.form = new Form(formFields);
            })
    },
    methods: {
        beforeSubmit(form) {
            for (const type in this.fields) {
                this.fields[type].options.forEach(option => {
                    option.fields.forEach((field) => {
                        if (
                            form[type + '[' + (field.reference_id ?? '') + ']' + '[' + field.name + ']'] === null ||
                            form[type + '[' + (field.reference_id ?? '') + ']' + '[' + field.name + ']'] === ''
                        ) {
                            delete form[type + '[' + (field.reference_id ?? '') + ']' + '[' + field.name + ']'];
                            return;
                        }

                        let fieldValue = form[type + '[' + (field.reference_id ?? '') + ']' + '[' + field.name + ']'];

                        if (field.type === 'currency') {
                            fieldValue = formatCurrencyInput(fieldValue);
                        }

                        form[type + '[' + (field.reference_id ?? '') + ']' + '[' + field.name + ']'] = fieldValue
                    })
                })
            }

            return form
        },
        fieldLabel(field) {
            if (typeof field.type === 'undefined' || field.type === 'currency') {
                return field.label + ' (' + this.$options.filters.currency(field.placeholder / 100) + ')'
            }

            return field.label + ' (' + field.placeholder + ')'
        }
    }
}
</script>

<style scoped>

</style>
