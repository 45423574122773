<template>
	<transition name="fade">
		<div class="modal is-active" v-show="visible" id="popup-email">
			<div class="modal-background" @click="this.destroy"></div>
			<div class="modal-content">
				<div class="popup-dialog" v-if="response">
					<div class="popup-body">

                        <div class="notification is-size-7 p-0 is-fullwidth" v-if="response.attempts >= 1 && response.initial_attempt_id !== null">
                            <div class="column">
                                <i18n path="developers.webhooks.log_popup.attempts" tag="strong">
                                    <template v-slot:task>
                                        <u class="is-clickable" href="#" @click="openLog(response.initial_attempt_id)">{{ $t('developers.webhooks.log_popup.attempts_this_task') }}</u>
                                    </template>
                                </i18n>
                            </div>
                        </div>

                        <div class="notification is-danger is-size-7 p-0 is-fullwidth" v-if="isBlockedRequest === true">
                            <div class="column">
                                <strong>{{ $t('developers.webhooks.log_popup.acl_blockage') }}</strong>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-fifth">
                                <strong>{{ $t('developers.webhooks.log_popup.event') }}</strong>
                            </div>

                            <div class="column">
                                <small>
                                    {{ response.event }}
                                </small>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-fifth">
                                <strong>{{ $t('developers.webhooks.log_popup.status') }}</strong>
                            </div>

                            <div class="column" v-if="isBlockedRequest === true">
                                <strong class="tag is-danger">
                                    {{ $t('developers.webhooks.table.blocked') }}
                                </strong>
                            </div>

                            <div class="column" v-else>
                                <strong class="tag" :class="getClassByStatus(response.response_status)">{{ response.response_status }}</strong>
                                <small class="pl-1" v-if="getStatusMessageByCode(response.response_status) !== ''">
                                    <a :href="`https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/${response.response_status}`"
                                       target="_blank" rel="noreferrer">
                                        {{ getStatusMessageByCode(response.response_status) }}
                                    </a>
                                </small>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-fifth">
                                <strong>{{ $t('developers.webhooks.log_popup.path') }}</strong>

                                <small v-if="isBlockedRequest === true">
                                    <i class="icon has-text-danger acl-blockage-icon">warning</i>
                                </small>
                            </div>

                            <div class="column">
                                <small>
                                    {{ response.endpoint }}
                                </small>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-fifth">
                                <strong>{{ $t('developers.webhooks.log_popup.date') }}</strong>
                            </div>

                            <div class="column">
                                {{ response.delivered_at }}
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-fifth">
                                <strong>{{ $t('developers.webhooks.log_popup.execution_time') }}</strong>
                            </div>

                            <div class="column">
                                {{ (response.response_time / 1000).toFixed(3) }} {{ $t('developers.webhooks.log_popup.execution_time_suffix') }}
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-fifth">
                                <strong>{{ $t('developers.webhooks.log_popup.identifier') }}</strong>
                            </div>

                            <div class="column">
                                {{ this.$props.id }}
                            </div>
                        </div>

                        <div class="columns" v-if="response.attempts >= 1">
                            <div class="column is-one-fifth">
                                <strong>{{ $t('developers.webhooks.log_popup.retries') }}</strong>
                            </div>

                            <div class="column">
                                {{ response.attempts }}
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-one-fifth">
                                <strong>{{ $t('developers.webhooks.log_popup.signature') }}</strong>
                            </div>

                            <div class="column is-size-7 mt-1">
                                {{ response.signature }}
                            </div>
                        </div>

                        <hr>

                        <label>{{ $t('developers.webhooks.log_popup.context') }}</label>

                        <div class="mt-3">
                            <a v-if="response && response.payload === null || response.payload === '[]'"
                               class="disabled-link">
                                <i class="icon">expand_more</i>
                                {{ $t('developers.webhooks.log_popup.request_payload') }}
                            </a>

                            <a href="#" @click.prevent="details.showPayload = !details.showPayload" v-else>
                                <i class="icon">{{ details.showPayload ? 'expand_less' : 'expand_more' }}</i>
                                {{ $t('developers.webhooks.log_popup.request_payload') }}
                            </a>

                            <button class="button is-small is-pulled-right px-3 py-1"
                                    @click="copyToClipboard(response.payload)"
                                    v-if="details.showPayload">
                                <i class="icon">content_copy</i>
                            </button>

                            <pre v-if="details.showPayload" v-html="highlightedPayload" class="prism-json mt-2"></pre>
                        </div>

                        <div class="mt-3">
                            <a v-if="response && response.headers === null || response.headers === '[]'"
                               class="disabled-link">
                                <i class="icon">expand_more</i>
                                {{ $t('developers.webhooks.log_popup.request_headers') }}
                            </a>

                            <a href="#" @click.prevent="details.showHeaders = !details.showHeaders" v-else>
                                <i class="icon">{{ details.showHeaders ? 'expand_less' : 'expand_more' }}</i>
                                {{ $t('developers.webhooks.log_popup.request_headers') }}
                            </a>

                            <button class="button is-small is-pulled-right px-3 py-1"
                                    @click="copyToClipboard(response.headers)"
                                    v-if="details.showHeaders">
                                <i class="icon">content_copy</i>
                            </button>

                            <pre v-if="details.showHeaders" v-html="highlightedHeaders" class="prism-json mt-2"></pre>
                        </div>

                        <div class="mt-3">
                            <a v-if="response && response.response_headers === null || response.response_headers === '[]'"
                               class="disabled-link">
                                <i class="icon">expand_more</i>
                                {{ $t('developers.webhooks.log_popup.request_response_headers') }}
                            </a>

                            <a href="#" @click.prevent="details.showResponseHeaders = !details.showResponseHeaders" v-else>
                                <i class="icon">{{ details.showResponseHeaders ? 'expand_less' : 'expand_more' }}</i>
                                {{ $t('developers.webhooks.log_popup.request_response_headers') }}
                            </a>

                            <button class="button is-small is-pulled-right px-3 py-1"
                                    @click="copyToClipboard(response.response_headers)"
                                    v-if="details.showResponseHeaders">
                                <i class="icon">content_copy</i>
                            </button>

                            <pre v-if="details.showResponseHeaders" v-html="highlightedResponseHeaders" class="prism-json mt-2"></pre>
                        </div>
					</div>

					<div class="popup-footer">
                        <button class="button is-rounded" @click="this.destroy">
                            {{ $t('developers.webhooks.log_popup.close') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<style>
@import 'prismjs/themes/prism.min.css';
</style>

<script>
import * as Http from '../../../constants/Http'
import Prism from 'prismjs'
import 'prismjs/components/prism-json.min'
import 'prismjs/themes/prism.css'

export default {
    props: {
        id: {
            type: String,
        },
        webhookId: {
            type: String,
        },
    },

    data() {
        return {
            response: null,
            details: {
                showHeaders:         false,
                showPayload:         true,
                showResponseHeaders: false,
            }
        }
    },

    async mounted() {
        const request = await axios.get(`/api/webhooks/${this.$props.webhookId}/logs/${this.$props.id}`)

        if (request.status !== 200) {
            this.destroy()
            return false
        }

        this.response = request.data.data
    },

    computed: {
        highlightedPayload () {
            return this.response ? Prism.highlight(this.prettify(this.response.payload), Prism.languages.json, 'json') : ''
        },

        highlightedHeaders () {
            return this.response ? Prism.highlight(this.prettify(this.response.headers), Prism.languages.json, 'json') : ''
        },

        highlightedResponseHeaders () {
            return this.response ? Prism.highlight(this.prettify(this.response.response_headers), Prism.languages.json, 'json') : ''
        },

        isBlockedRequest () {
            return this.response
                && typeof this.response.blocked_by_acl !== 'undefined'
                && this.response.blocked_by_acl === true
        },
    },

    methods: {
        copyToClipboard(data) {
            navigator.clipboard.writeText(data)

            this.$buefy.toast.open({
                message: this.$t('developers.webhooks.log_popup.copied_to_clipboard'),
                type: 'is-success'
            })
        },

        openLog(id) {
            this.destroy()
            this.$popup('WebhookLogItem', {
                id: id,
                webhookId: this.webhookId
            })
        },

        getStatusMessageByCode(code) {
            return Http.STATUS_CODES[code] ? Http.STATUS_CODES[code] : ''
        },

        prettify(data) {
            try {
                return JSON.stringify(JSON.parse(data), null, 4)
            } catch (e) {
                return data
            }
        },

        getClassByStatus(type) {
            if (type >= 200 && type < 300) {
                // Success
                return 'is-success'
            } else if (type >= 400 && type < 500) {
                // Client error
                return 'is-warning'
            } else if (type >= 500 && type < 600) {
                // Server error
                return 'is-danger'
            }

            return 'is-light'
        },
    }
}
</script>

<style scoped>
@import 'prismjs/themes/prism.min.css';

.prism-json {
    font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    padding: 1em;
    background-color: #f5f5f5;
    border-radius: 0.313rem;
}
</style>
