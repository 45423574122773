<template>
    <tab-content title="Mailinglijst">

        <g-table
            sort-column="id"
            :url="`/api/admin/company/${this.$route.params.company_id}/mailing-list`"
        >
            <b-table-column field="email" label="E-mailadres" v-slot="props">
                {{ props.row.email }}
            </b-table-column>

            <b-table-column field="opted_in_at" label="Ingeschreven op" v-slot="props">
                {{ $i18nDate(new Date(props.row.opted_in_at), 'numeric') }}
            </b-table-column>

            <b-table-column numeric :label="$t('admin.transactions.action')" v-slot="props">
                <div class="buttons">
                    <router-link class="button" :to="{ name: 'admin.orders', query: { order: props.row.id }}">
                        <i class="icon">north</i>
                    </router-link>
                </div>
            </b-table-column>
        </g-table>

        <button class="button" id="export" :class="{ 'is-loading': exportLoading }" @click="exportList">
            <i class="icon">file_download</i>
            <span>Lijst exporteren</span>
        </button>

    </tab-content>
</template>

<script>
import TabContent from "../../../components/TabView/TabContent.vue";

export default {
    name: "Mailinglist",

    components: {
        TabContent
    },

    data() {
        return {
            exportLoading: false
        }
    },

    methods: {
        exportList: function () {
            this.exportLoading = true

            axios.get(`/api/admin/company/${ this.$route.params.company_id }/mailing-list/export`)
                 .then(response => {
                     const url = window.URL.createObjectURL(new Blob([response.data]))
                     const link = document.createElement('a')

                     link.href = url
                     link.setAttribute('download', `Mailinglijst_${ this.$route.params.company_id }.csv`)

                     document.body.appendChild(link)

                     link.click()
                     link.remove()
                 }).finally(() => {
                     this.exportLoading = false
                 })
        },
    },
}
</script>

