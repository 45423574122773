<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">

                    <div class="popup-title">
                        <h1 class="title is-1">
                            {{ $t('developers.webhooks.form.title') }}
                        </h1>

                        <div class="popup-close" @click.prevent="destroy">
                            <i class="icon">close</i>
                        </div>
                    </div>

                    <g-form :action="formEndpoint"
                            :method="formMethod"
                            :form="form"
                            :before="formatData"
                            :prepopulate="prepopulate"
                            @prepopulated="prepopulated"
                            @success="formSuccess"
                            message="Wijzigingen succesvol opgeslagen">

                        <div class="popup-body popup-rows">
                            <div class="columns">
                                <div class="column is-one-third">
                                    <label>{{ $t('developers.webhooks.form.name') }}</label>
                                </div>

                                <div class="column">
                                    <div class="field-body">
                                        <g-input name="name" :label="$t('developers.webhooks.form.name')"/>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-third">
                                    <label>{{ $t('developers.webhooks.form.url') }}</label>
                                </div>

                                <div class="column">
                                    <div class="field-body">
                                        <g-input name="endpoint" :label="$t('developers.webhooks.form.url_description')"/>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-third">
                                    <label>{{ $t('developers.webhooks.form.events') }}</label>
                                </div>

                                <div class="column">
                                    <div class="field-body" id="webhook-select">
                                        <tree-select :multiple="true"
                                                     :placeholder="$t('developers.webhooks.form.select_placeholder')"
                                                     :options="eventOptions"
                                                     :clearable="false"
                                                     v-model="form.events"/>
                                    </div>
                                </div>
                            </div>

                            <div class="columns" v-if="!webhookId">
                                <div class="column is-one-third">
                                    <label>{{ $t('developers.webhooks.form.secret') }}</label>
                                </div>

                                <div class="column">
                                    <div class="field-body">
                                        <g-input name="secret" :label="$t('developers.webhooks.form.secret_description')"/>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-third">
                                    <label>{{ $t('developers.webhooks.form.active') }}</label>
                                </div>

                                <div class="column is-vcentered">
                                    <div class="field-body">
                                        <g-switch name="status"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="popup-footer">
                            <a @click.prevent="destroy" href="#" role="button">{{ $t('developers.webhooks.form.cancel') }}</a>
                            <g-button>{{ $t('developers.webhooks.form.save') }}</g-button>
                        </div>
                    </g-form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    data() {
        const formData = {
            name: null,
            endpoint: null,
            events: [],
            secret: null,
            status: 1,
        }

        return {
            form: new Form(formData),
            eventOptions: [
                {
                    'id': 'giftcard.issue',
                    'label': 'giftcard.issue'
                },
                {
                    'id': 'giftcard.redeem',
                    'label': 'giftcard.redeem'
                },
                {
                    'id': 'transaction.captured',
                    'label': 'transaction.captured'
                },
                {
                    'id': 'location.created',
                    'label': 'location.created'
                },
                {
                    'id': 'location.updated',
                    'label': 'location.updated'
                },
                {
                    'id': 'location.deleted',
                    'label': 'location.deleted'
                },
            ]
        }
    },

    props: {
        webhookId: {
            type: String,
            required: false,
        },
    },

    computed: {
        formEndpoint() {
            return this.webhookId
                ? `/api/webhooks/${this.webhookId}`
                : `/api/webhooks`
        },

        formMethod() {
            return this.webhookId
                ? 'patch'
                : 'post'
        },

        prepopulate() {
            return !!this.webhookId
        },
    },

    methods: {
        prepopulated({ data }) {
            this.form.status = data.status === 'active'
                ? 1
                : 0
        },

        formSuccess() {
            this.$emit(this.webhookId ? 'webhook-updated' : 'webhook-created')
            this.destroy()
        },

        formatData(form) {
            console.log(form)

            form.status = form.status === 1
                ? 'active'
                : 'inactive'

            return form
        },
    },
}
</script>
