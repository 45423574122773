<template>
	<transition name="fade">
		<div class="modal is-active" v-show="visible" id="popup-email">
			<div class="modal-background" @click="destroy"></div>
			<div class="modal-content">
				<div class="popup-dialog" v-if="email">
					<div class="popup-title">
						<h1 class="title is-1">E-mail: {{ email.id }}</h1>

            <div class="popup-close" @click.prevent="destroy">
                <i class="icon">close</i>
            </div>
					</div>

					<div class="popup-body">
                        <div class="columns">
                            <div class="column is-one-quarter"><strong>E-mail type</strong></div>
                            <div class="column">{{ email.type ? email.type : '-' }}</div>
                        </div>
                        <div class="columns" v-if="typeof email.company_id !== 'undefined' && email.company_id !== null">
                            <div class="column is-one-quarter"><strong>Bedrijf ID</strong></div>
                            <div class="column">
                                <router-link :to="{ name: 'admin.company.overview', params: { company_id: email.company_id }}"
                                             target='_blank'>
                                    {{ email.company_id }}
                                </router-link>
                            </div>
                        </div>
						<div class="columns">
							<div class="column is-one-quarter"><strong>Onderwerp</strong></div>
							<div class="column">{{ email.subject }}</div>
						</div>
						<div class="columns">
							<div class="column is-one-quarter"><strong>Ontvanger</strong></div>
							<div class="column" v-html="emailLink(email.to)"></div>
						</div>
						<div class="columns" v-if="email.cc">
							<div class="column is-one-quarter"><strong>CC</strong></div>
							<div class="column" v-html="emailLink(email.cc)"></div>
						</div>
						<div class="columns" v-if="email.bcc">
							<div class="column is-one-quarter"><strong>CC</strong></div>
							<div class="column" v-html="emailLink(email.bcc)"></div>
						</div>
						<div class="columns">
							<div class="column is-one-quarter"><strong>Verzonden op</strong></div>
							<div class="column">{{ email.created_at }}</div>
						</div>

						<a href="/headers" @click.prevent="showHeaders = !showHeaders">Laat headers zien</a>
						<pre v-show="showHeaders">
							<code>
								{{ email.headers }}
							</code>
						</pre>

						<div ref="emailBody"></div>
					</div>

					<div class="popup-footer">
						<button class="button is-rounded" @click.prevent="destroy">
							Sluiten
						</button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
    export default {
        props: {
            id: {
                type: String,
			},
		},
		data() {
            return {
				email: false,
				showHeaders: false,
			}
		},
		async mounted() {

            const request = await axios.get(`/api/admin/emails/${this.id}`)

			if (request.status !== 200) {
				this.destroy()
				return false
			}
			this.email = request.data
			this.renderEmail()
		},
		methods: {
            /**
             * BTOA method can't handle UTF-8 but ASCII instead
             * Data URI's uses UTF-8 so it needs to be converted
             */
            utfToAscii (string) {
                // Will fetch the character code from UTF range and sets it to hex decimals (readable for all)
                return string.replace(/[\u00A0-\u2666]/g, function (c) {
                    return '&#' + c.charCodeAt(0) + ';'
                })
            },
            renderEmail() {
                const iframe        = document.createElement('iframe')
                const utfText       = this.utfToAscii(this.email.body)
                iframe.src          = 'data:text/html;base64,' + btoa(unescape(encodeURIComponent((utfText))))
                iframe.style.width  = '100%'
                iframe.style.height = '400px'

				const waitFor = setInterval(() => {
				    if (typeof this.$refs.emailBody !== 'undefined') {
                        this.$refs.emailBody.appendChild(iframe)
						clearInterval(waitFor)
                    }
                }, 100)
			},

			emailLink(email) {
                return `<a href="mailto:${email}">${email}</a>`
			}
		}
    }
</script>
