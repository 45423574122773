import {getRedirectable, getRoute} from './menu'
import Overview from "./views/admin/companies/Overview.vue";
import Pricing from "./views/admin/companies/Pricing.vue";
import MailingList from "./views/admin/companies/Mailinglist.vue";
import Invoices from "./views/admin/companies/Invoices.vue";
import Printing from "./views/admin/companies/Printing.vue";
import Widget from "./views/admin/companies/Widget.vue";
import GiftCard from "./views/admin/companies/GiftCard.vue";
import Settings from "./views/admin/companies/Settings.vue";
import Translations from "./views/admin/companies/Translations.vue";
import Restrictions from "./views/admin/companies/Restrictions.vue";

export default [

    // Style guide
    {
        name: 'style.guide',
        path: '/styleguide',
        component: () => import('./views/styleguide/Demo'),
        meta: {
            permissions: ['admin_gifty'],
            must_have_current_membership: true,
        }
    },

    // Style guide
    {
        name: 'style.example',
        path: '/style-example',
        component: () => import('./views/styleguide/StyleExample.vue'),
        meta: {
            permissions: ['admin_gifty']
        }
    },

    // Login
    {
        name: 'auth.login',
        path: '/login',
        component: () => import('./views/auth/Login'),
    },

    // Password reset
    {
        name: 'password.reset',
        path: '/password/reset',
        component: () => import('./views/auth/PasswordReset'),
    },

    {
        name: 'password.reset.confirm',
        path: '/password/reset/:token',
        component: () => import('./views/auth/PasswordReset'),
    },

    // Homepage / Dashboard / Auth
    {
        name: 'home',
        path: '/',
        component: () => import('./views/Home'),
        meta: {
            title: 'Verzilveren of uitgeven',
            must_have_current_membership: true
        }
    },

    // Register page
    {
        name: 'register.confirmation',
        path: '/register/thanks',
        component: () => import('./views/auth/Register'),
        meta: {
            title: 'Bedankt!',
        },
    },

    // Register page
    {
        name: 'register',
        path: '/register/:prefilled_email?',
        component: () => import('./views/auth/Register'),
        props: true,
        meta: {
            title: 'Aanmelden',
        },
    },

    // GiftCards redirect
    {
        name: 'giftcards',
        path: '/giftcards',
        redirect: 'transactions',
    },

    // Transactions overview
    {
        name: 'transactions',
        path: '/transactions',
        component: () => import('./views/Transactions'),
        meta: {
            permissions: ['statistics'],
            title: 'Transacties',
            must_have_current_membership: false,
        },
    },

    // Shop redirect
    {
        name: 'shop',
        path: '/shop',
        redirect: getRedirectable,
        meta: {
            permissions: ['administrator'],
            must_have_current_membership: true,
            hide_from_collaborative_accounts: true,
        }
    },

    // Shop stock management
    {
        name: 'shop.stock',
        path: '/shop/stock',
        component: () => import('./views/shop/Stock'),
        props: true,
        meta: {
            permissions: ['administrator'],
            title: 'Fysieke bonnen bestellen',
            must_have_current_membership: true,
            hide_from_collaborative_accounts: true,
        }
    },

    // Redirect old promotional page to digital gift cards
    {
        name: 'shop.promotional',
        path: '/shop/promotional',
        redirect: () => getRoute('shop.digital'),
    },

    // Shop digital gift cards
    {
        name: 'shop.digital',
        path: '/shop/digital',
        component: () => import('./views/shop/Digital'),
        meta: {
            permissions: ['administrator'],
            title: 'Digitale bonnen bestellen',
            must_have_current_membership: true,
            hide_from_collaborative_accounts: true,
        }
    },

    // Shop wrappings
    {
        name: 'shop.restocking',
        path: '/shop/restocking',
        component: () => import('./views/shop/Restocking'),
        meta: {
            permissions: ['administrator'],
            title: 'Automatisch aanvullen',
            must_have_current_membership: true,
            hide_from_collaborative_accounts: true,
        }
    },

    // My account
    {
        name: 'settings.account',
        path: '/settings/account',
        component: () => import('./views/settings/Account'),
        meta: {
            permissions: [],
            title: 'Mijn account'
        }
    },

    // Employees index
    {
        name: 'settings.employees',
        path: '/settings/employees',
        component: () => import('./views/settings/employees/Index'),
        meta: {
            permissions: ['administrator'],
            title: 'Medewerkers',
        }
    },

    // Employees add
    {
        name: 'settings.employees.add',
        path: '/settings/employees/add',
        component: () => import('./views/settings/employees/Add'),
        meta: {
            permissions: ['administrator'],
            title: 'Werknemer toevoegen',
        }
    },

    // Employees edit
    {
        name: 'settings.employees.edit',
        path: '/settings/employees/edit/:id',
        component: () => import('./views/settings/employees/Edit'),
        props: true,
        meta: {
            permissions: ['administrator'],
            title: 'Werknemer aanpassen',
        }
    },

    //Module redirect
    {
        name: 'settings.module',
        path: '/settings/module',
        redirect: getRedirectable,
        meta: {
            permissions: ['administrator'],
            hide_from_collaborative_accounts: true
        }
    },

    // Module styling
    {
        name: 'settings.module.styling',
        path: '/settings/module/styling',
        component: () => import('./views/module/ModuleStyling'),
        meta: {
            permissions: ['administrator'],
            title: 'Bestelmodule stijlen',
            hide_from_collaborative_accounts: true,
        }
    },

    // Module placement
    {
        name: 'settings.module.placement',
        path: '/settings/module/placement',
        component: () => import('./views/module/ModulePlacement'),
        meta: {
            permissions: ['administrator'],
            title: 'Bestelmodule plaatsen',
            hide_from_collaborative_accounts: true,
        }
    },

    // Module pricing
    {
        name: 'settings.module.pricing',
        path: '/settings/module/pricing',
        component: () => import('./views/module/Pricing'),
        meta: {
            permissions: ['administrator'],
            title: 'Prijsinstellingen',
            hide_from_collaborative_accounts: true,
        }
    },

    // Module giftcard packages
    {
        name: 'settings.module.packages',
        path: '/settings/module/packages',
        component: () => import('./views/module/Packages'),
        meta: {
            permissions: ['administrator'],
            title: 'Arrangementen',
            hide_from_collaborative_accounts: true,
        }
    },

    // Module create giftcard package
    {
        name: 'settings.module.packages.create',
        path: '/settings/module/packages/create',
        component: () => import('./views/module/PackageForm'),
        props: {
            create: true,
        },
        meta: {
            permissions: ['administrator'],
            title: 'Financieel',
        }
    },

    // Module edit giftcard package
    {
        name: 'settings.module.packages.edit',
        path: '/settings/module/packages/edit/:id',
        component: () => import('./views/module/PackageForm'),
        props: {
            create: false,
        },
        meta: {
            permissions: ['administrator'],
            title: 'Financieel',
        }
    },

    // Module Action Codes
    {
        name: 'settings.module.action_codes',
        path: '/settings/module/action-codes',
        component: () => import('./views/module/ActionCodes'),
        meta: {
            permissions: ['administrator'],
            title: 'Actiecodes',
            hide_from_collaborative_accounts: true,
        }
    },

    // Module payment methods
    {
        name: 'settings.module.payments',
        path: '/settings/module/payments',
        component: () => import('./views/module/PaymentMethods'),
        meta: {
            permissions: ['administrator'],
            title: 'Betaalopties',
        }
    },

    // Giftcard redirect
    {
        name: 'settings.giftcard',
        path: '/settings/giftcard',
        redirect: 'settings.giftcard.builder',
    },

    // Giftcard Editor
    {
        name: 'settings.giftcard.builder',
        path: '/settings/giftcard/editor/:setup?',
        component: () => import('./components/Design/Builder/Builder'),
        props: true,
        meta: {
            permissions: ['administrator'],
            title: 'Cadeaubon',
            hide_from_collaborative_accounts: true
        }
    },

    // Manage wrapping
    {
        name: 'settings.giftcard.wrapping',
        path: '/settings/giftcard/wrapping',
        component: () => import('./components/Design/Wrapping/Index'),
        props: true,
        meta: {
            permissions: ['administrator'],
            title: 'Verpakking',
            hide_from_collaborative_accounts: true
        }
    },

    // Wallet design
    {
        name: 'settings.giftcard.wallet',
        path: '/settings/giftcard/wallet',
        component: () => import('./components/Design/Wallet/Index'),
        meta: {
            permissions: ['administrator'],
            title: 'Wallet',
            hide_from_collaborative_accounts: true
        }
    },

    // Company redirect
    {
        name: 'settings.company',
        path: '/settings/company',
        redirect: getRedirectable,
        meta: {
            permissions: ['administrator'],
        }
    },

    // Company Details
    {
        name: 'settings.company.details',
        path: '/settings/company/details',
        component: () => import('./views/settings/company/CompanyDetails'),
        props: true,
        meta: {
            permissions: ['administrator'],
            title: 'Organisatie',
        }
    },

    // Company Locations
    {
        name: 'settings.company.locations',
        path: '/settings/company/locations',
        component: () => import('./views/settings/company/CompanyLocations/Index'),
        props: true,
        meta: {
            permissions: ['administrator'],
        }
    },

    // Company Locations: add
    {
        name: 'settings.company.locations.add',
        path: '/settings/company/locations/add',
        component: () => import('./views/settings/company/CompanyLocations/Form'),
        meta: {
            permissions: ['administrator'],
        }
    },

    // Company Locations: edit
    {
        name: 'settings.company.locations.edit',
        path: '/settings/company/locations/edit/:id',
        component: () => import('./views/settings/company/CompanyLocations/Form'),
        props: true,
        meta: {
            permissions: ['administrator'],
        }
    },

    // Company Collaborations
    {
        name: 'settings.company.collaborations',
        path: '/settings/company/collaborations',
        component: () => import('./views/settings/company/CompanyCollaborations'),
        props: true,
        meta: {
            permissions: ['administrator'],
            title: 'Samenwerken',
        }
    },


    // Company developers (REST API & Webhooks)
    {
        name: 'settings.company.developers',
        path: '/settings/company/developers',
        component: () => import('./views/settings/company/CompanyDevelopers/Index'),
        props: true,
        meta: {
            permissions: ['administrator'],
            title: 'Developers',
        }
    },

    // Company developers (API)
    {
        name: 'settings.company.developers.api',
        path: '/settings/company/developers/api',
        redirect: 'settings/company/developers',
    },
    {
        name: 'settings.company.developers.api.token',
        path: '/settings/company/developers/token/:id',
        component: () => import('./views/settings/company/CompanyDevelopers/API/Token.vue'),
        props: true,
        meta: {
            permissions: ['administrator'],
            title: 'Developers',
        }
    },

    // Company developers (Webhooks)
    {
        name: 'settings.company.developers.webhooks.logs',
        path: '/settings/company/developers/webhooks/:id',
        component: () => import('./views/settings/company/CompanyDevelopers/Webhooks/View.vue'),
        props: true,
        meta: {
            permissions: ['administrator'],
            title: 'Webhooks',
        }
    },

    // Company details (Gifty.nl)
    {
        name: 'settings.module.platform_settings',
        path: '/settings/module/platform-settings',
        component: () => import('./views/module/PlatformSettings'),
        meta: {
            permissions: ['administrator'],
            title: 'Gifty.nl',
            hide_from_collaborative_accounts: true,
        }
    },

    // Finance redirect
    {
        name: 'settings.finance',
        path: '/settings/finance',
        redirect: getRedirectable,
        meta: {
            permissions: ['financials'],
            hide_from_collaborative_accounts: true
        }
    },

    // Finance reports
    {
        name: 'settings.finance.report',
        path: '/settings/finance/report',
        component: () => import('./views/finance/report/Reports.vue'),
        meta: {
            permissions: ['financials'],
            required_features: ['reports'],
        },
        children: [
            {
                // Own gift card
                name: 'settings.finance.report.own_brand',
                path: 'overview',
                component: () => import('./views/finance/report/OwnBrand.vue'),
                meta: {
                    permissions: ['financials'],
                    required_features: ['reports'],
                },
            },
            {
                // Collaborations
                name: 'settings.finance.report.collaboration',
                path: 'collaboration',
                component: () => import('./views/finance/report/Collaboration.vue'),
                meta: {
                    permissions: ['financials'],
                    required_features: ['reports'],
                },
            },
            {
                // Cumulative
                name: 'settings.finance.report.cumulative',
                path: 'cumulative',
                component: () => import('./views/finance/report/Cumulative.vue'),
                meta: {
                    permissions: ['financials'],
                    required_features: ['reports'],
                },
            },
            {
                // Settings
                name: 'settings.finance.report.settings',
                path: 'settings',
                component: () => import('./views/finance/report/Settings.vue'),
                meta: {
                    permissions: ['financials'],
                    required_features: ['reports'],
                },
            },
        ],
    },

    // Finance membership
    {
        name: 'settings.finance.membership',
        path: '/settings/finance/membership',
        component: () => import('./views/finance/Membership'),
        meta: {
            permissions: ['administrator'],
            title: 'Financieel'
        }
    },

    // Finance balance
    {
        name: 'settings.finance.balance',
        path: '/settings/finance/balance',
        component: () => import('./views/finance/Balance'),
        meta: {
            permissions: ['financials'],
            title: 'Financieel'
        }
    },

    // Finance invoices
    {
        name: 'settings.finance.invoices',
        path: '/settings/finance/invoices',
        component: () => import('./views/finance/Invoices'),
        meta: {
            permissions: ['financials'],
            title: 'Financieel'
        }
    },

    // Finance payments
    {
        name: 'settings.finance.payment-settings',
        path: '/settings/finance/payment-settings',
        component: () => import('./views/finance/PaymentSettings'),
        meta: {
            permissions: ['financials'],
            title: 'Financieel'
        }
    },

    // Finance splitpay
    {
        name: 'settings.finance.collaborationsettle',
        path: '/settings/finance/collaboration-settle',
        component: () => import('./views/finance/CollaborationSettle'),
        meta: {
            must_have_current_membership: true,
            permissions: ['financials'],
            title: 'Financieel'
        }
    },

    // Finance payouts
    {
        name: 'settings.finance.payouts',
        path: '/settings/finance/payouts',
        component: () => import('./views/finance/Payout'),
        meta: {
            permissions: ['financials'],
            title: 'Financieel',
        }
    },

    // Admin marketing overview
    {
        name: 'admin.marketing',
        path: '/admin/marketing',
        redirect: getRedirectable,
        meta: {
            permissions: ['admin_gifty']
        },
    },

    // Admin marketing partners overview
    {
        name: 'admin.marketing.partners',
        path: '/admin/marketing/partners',
        component: () => import('./views/admin/partners/Partners'),
        meta: {
            permissions: ['admin_gifty'],
        },
    },

    // Admin marketing campaigns overview
    {
        name: 'admin.marketing.campaigns',
        path: '/admin/marketing/campaigns',
        component: () => import('./views/admin/campaigns/Campaigns'),
        meta: {
            permissions: ['admin_gifty'],
        },
    },

    // Admin marketing referrals overview
    {
        name: 'admin.marketing.affiliates',
        path: '/admin/marketing/affiliates',
        component: () => import('./views/admin/affiliates/Index'),
        meta: {
            permissions: ['admin_gifty'],
        },
    },

    // Admin accounting overview
    {
        name: 'admin.accounting',
        path: '/admin/accounting',
        redirect: getRedirectable,
        meta: {
            permissions: ['admin_gifty']
        },
    },

    // Admin accounting invoices
    {
        name: 'admin.accounting.invoices',
        path: '/admin/accounting/invoices',
        component: () => import('./views/admin/accounting/Invoices'),
        meta: {
            permissions: ['admin_gifty']
        },
    },

    // Admin accounting direct debits
    {
        name: 'admin.accounting.direct_debits',
        path: '/admin/accounting/direct-debits',
        component: () => import('./views/admin/accounting/DirectDebits'),
        meta: {
            permissions: ['admin_gifty']
        },
    },

    // Admin accounting invoice presets
    {
        name: 'admin.accounting.invoice_presets',
        path: '/admin/accounting/invoicePresets',
        component: () => import('./views/admin/accounting/InvoicePresets'),
        meta: {
            permissions: ['admin_gifty']
        },
    },

    // Admin accounting ledger accounts
    {
        name: 'admin.accounting.ledger_accounts',
        path: '/admin/accounting/ledgerAccounts',
        component: () => import('./views/admin/accounting/LedgerAccounts'),
        meta: {
            permissions: ['admin_gifty']
        },
    },

    // Admin overview
    {
        name: 'admin',
        path: '/admin',
        redirect: getRedirectable,
        meta: {
            permissions: ['admin_gifty']
        },
    },

    // Admin emails overview
    {
        name: 'admin.emails',
        path: '/admin/email',
        component: () => import('./views/admin/Emails'),
        meta: {
            permissions: ['admin_gifty'],
        },
    },


    // Admin email templates overview
    {
        name: 'admin.email.templates',
        path: '/admin/email/templates',
        component: () => import('./views/admin/EmailTemplates'),
        meta: {
            permissions: ['admin_gifty'],
        },
    },

    // Admin orders overview
    {
        name: 'admin.orders',
        path: '/admin/orders',
        component: () => import('./views/admin/Orders'),
        meta: {
            permissions: ['admin_gifty'],
        },
    },

    // Admin order view
    {
        name: 'admin.order',
        path: '/admin/order/:order_id',
        component: () => import('./views/admin/Order'),
        meta: {
            permissions: ['admin_gifty'],
        },
    },

    // Admin gift card view
    {
        name: 'admin.giftcards',
        path: '/admin/giftcards',
        component: () => import('./views/admin/giftcards/Giftcards'),
        meta: {
            permissions: ['admin_gifty']
        }
    },

    // Admin inventory management view
    {
        name: 'admin.inventory_management',
        path: '/admin/inventory',
        component: () => import('./views/admin/inventory/InventoryManagement'),
        meta: {
            permissions: ['admin_gifty']
        }
    },

    // Admin users overview
    {
        name: 'admin.users',
        path: '/admin/users',
        component: () => import('./views/admin/users/Users'),
        meta: {
            permissions: ['admin_gifty']
        }
    },

    // Admin transactions overview
    {
        name: 'admin.transactions',
        path: '/admin/transactions',
        component: () => import('./views/admin/Transactions'),
        meta: {
            permissions: ['admin_gifty'],
        },
    },

    // Admin companies overview
    {
        name: 'admin.companies',
        path: '/admin/companies',
        component: () => import('./views/admin/companies/Companies'),
        meta: {
            permissions: ['admin_gifty'],
        },
    },

    // Admin company overview
    {
        name: 'admin.company',
        path: '/admin/company/:company_id',
        component: () => import('./views/admin/companies/Company'),
        meta: {
            permissions: ['admin_gifty'],
        },
        children: [
            {
                name: 'admin.company.overview',
                path: 'overview',
                component: Overview,
            },
            {
                name: 'admin.company.invoices',
                path: 'invoices',
                component: Invoices,
            },
            {
                name: 'admin.company.printing',
                path: 'printing',
                component: Printing,
            },
            {
                name: 'admin.company.giftCard',
                path: 'gift-card',
                component: GiftCard,
            },
            {
                name: 'admin.company.restrictions',
                path: 'restrictions',
                component: Restrictions,
            },
            {
                name: 'admin.company.pricing',
                path: 'pricing',
                component: Pricing,
            },
            {
                name: 'admin.company.mailinglist',
                path: 'mailinglist',
                component: MailingList,
            },
            {
                name: 'admin.company.widget',
                path: 'widget',
                component: Widget,
            },
            {
                name: 'admin.company.translations',
                path: 'translations',
                component: Translations,
            },
            {
                name: 'admin.company.settings',
                path: 'settings',
                component: Settings,
            },
        ],
    },

    // Admin fraud filters
    {
        name: 'admin.fraud.filters',
        path: '/admin/fraud/filters',
        component: () => import('./views/admin/FraudFilters'),
        props: true,
        meta: {
            permissions: ['admin_gifty'],
        },
    },

    // Admin tools
    {
        name: 'admin.tools',
        path: '/admin/tools',
        component: () => import('./views/admin/Tools'),
        props: true,
        meta: {
            permissions: ['admin_gifty'],
        },
    },

    // All other pages are not found
    {
        name: 'error.404',
        path: '*',
        component: () => import('./views/errors/404.vue'),
        meta: {
            title: 'Pagina niet gevonden'
        }
    },
]
