<template>
    <div class="tab-content">
        <h3 v-if="title" class="tab-header">{{ title }}</h3>

        <div v-if="description" class="tab-description">
            {{ description }}
        </div>

        <slot />
    </div>
</template>

<script>
export default {
    name: 'TabContent',

    props: {
        title: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        }
    }
}
</script>

<style scoped lang="scss">
.tab-header {
    font-size: 1.25rem;
}
</style>
