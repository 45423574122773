<template>
    <tab-content title="Cadeaubon restricties">
        <p class="tab-description">Dit formulier dient voor het instellen van diverse restricties op cadeaubonnen, voor
            overeenstemming met lokale en internationale regelgeving.</p>

        <g-form
            :action="`/api/admin/company/${this.$route.params.company_id}/giftCard`"
            :prepopulate="true"
            :prepopulate-with-post-response="true"
            @prepopulated="prepopulated"
            :before="beforeSubmit"
            :form="this.settingsForm"
            message="Instellingen opgeslagen"
            @loading-state="(state) => isLoading = state"
        >
            <div class="columns column-form is-desktop">
                <div class="column is-3-desktop is-2-widescreen">
                    <div class="tab-subheader">Algemeen</div>
                </div>

                <div class="column is-4-widescreen">
                    <div class="field-header">Maximaal uitgiftebedrag</div>
                    <p><small>Restrictie op het bedrag dat op een cadeaubon bijgeschreven kan worden.</small></p>
                    <g-format ref="card_issue_limit"
                              label="Bedrag"
                              name="card_issue_limit"
                              type="currency"/>

                    <div class="field-header">Maximaal verzilverbedrag</div>
                    <p><small>Restrictie op het bedrag dat in één transactie afgeschreven kan worden.</small></p>
                    <g-format ref="card_redeem_limit"
                              label="Bedrag"
                              name="card_redeem_limit"
                              type="currency"/>

                    <div class="field-header">Maximaal saldo</div>
                    <p><small>Hoogst toegestane saldo op een cadeaubon.</small></p>
                    <g-format ref="card_balance_limit"
                              label="Bedrag"
                              name="card_balance_limit"
                              type="currency"/>
                </div>
            </div>

            <div class="columns column-form is-desktop">
                <div class="column is-3-desktop is-2-widescreen">
                    <div class="tab-subheader">Webshop</div>
                </div>

                <div class="column is-4-widescreen">
                    <div class="field-header">Maximaal verzilverbedrag webshops</div>
                    <p><small>Limiet voor verzilveringen via een API-key van het type 'webshop'. Voor breed besteedbare cadeaubonnen: maximaal € 50.</small></p>
                    <g-format ref="online_redeem_limit"
                              label="Bedrag"
                              name="online_redeem_limit"
                              type="currency"/>
                </div>
            </div>

            <div class="columns column-form is-desktop">
                <div class="column is-3-desktop is-2-widescreen">
                    <div class="tab-subheader">Bestelmodule</div>
                </div>

                <div class="column is-4-widescreen">
                    <div class="field-header">Maximaal bonbedrag bestelmodule</div>
                    <p><small>Limiet voor het cadeaubonbedrag dat per bon besteld kan worden. Maximaal € 2.500.</small></p>
                    <g-format ref="giftcard_max"
                              label="Bedrag"
                              name="giftcard_max"
                              type="currency"/>

                    <div class="field-header">Maximaal bestelbedrag bestelmodule</div>
                    <p><small>Limiet voor de totale cadeaubonwaarde in één bestelling. Maximaal € 10.000.</small></p>
                    <g-format ref="order_max"
                              label="Bedrag"
                              name="order_max"
                              type="currency"/>
                </div>
            </div>

            <div class="columns column-form is-desktop">
                <div class="column is-3-desktop is-2-widescreen">
                    <div class="tab-subheader">Transacties</div>
                </div>

                <div class="column is-4-widescreen">
                    <div class="field-header">Opmerkingen bij transacties</div>

                    <div class="notification is-warning mt-2 mb-2"
                         v-if="settingsForm.transaction_description_requirement === 'required' &&
                               settingsForm.show_transaction_description_field === 0">
                        <small>
                            <strong>Let op:</strong>
                            opmerkingen bij transacties staat op 'Verplicht', maar het opmerkingenveld staat op 'Niet tonen'.
                        </small>
                    </div>

                    <p>
                        <small>
                            Bepaald of opmerkingen bij transacties optioneel of verplicht zijn voor medewerkers.
                        </small>
                    </p>

                    <g-select :options="transactionCommentOptions" name="transaction_description_requirement"/>

                    <p>
                        <small>
                            Bepaald of het opmerkingenveld getoond moet worden tijdens het innemen en uitgeven van cadeaubonnen.
                        </small>
                    </p>
                    <g-switch name="show_transaction_description_field" label="Opmerkingenveld tonen"/>

                    <g-button type="submit" :loading="isLoading">Opslaan</g-button>
                </div>
            </div>
        </g-form>
    </tab-content>
</template>

<script>
import {formatCurrencyInput} from "../../../utils/Consts";
import TabContent from "../../../components/TabView/TabContent.vue";

export default {
    name: "Pricing",
    components: {TabContent},
    data() {
        return {
            isLoading: false,
            settingsForm: new Form({
                card_balance_limit: null,
                card_redeem_limit: null,
                card_issue_limit: null,
                online_redeem_limit: null,
                giftcard_max: null,
                order_max: null,
                transaction_description_requirement: 'optional',
                show_transaction_description_field: false,
            }),
            transactionCommentOptions: [
                {
                    value: 'optional',
                    name: 'Optioneel',
                },
                {
                    value: 'required',
                    name: 'Verplicht',
                },
            ],
        }
    },
    methods: {
        prepopulated({data}) {
            this.settingsForm.show_transaction_description_field = +data.show_transaction_description_field

            if (data.online_redeem_limit !== null) {
                this.settingsForm.online_redeem_limit = (data.online_redeem_limit / 100)
            }

            if (data.card_balance_limit !== null) {
                this.settingsForm.card_balance_limit = (data.card_balance_limit / 100)
            }

            if (data.card_redeem_limit !== null) {
                this.settingsForm.card_redeem_limit = (data.card_redeem_limit / 100)
            }

            if (data.card_issue_limit !== null) {
                this.settingsForm.card_issue_limit = (data.card_issue_limit / 100)
            }

            if (data.giftcard_max !== null) {
                this.settingsForm.giftcard_max = (data.giftcard_max / 100)
            }

            if (data.order_max !== null) {
                this.settingsForm.order_max = (data.order_max / 100)
            }
        },
        beforeSubmit(form) {
            if (form.online_redeem_limit === '') {
                form.online_redeem_limit = null
            }

            if (form.card_balance_limit !== null) {
                form.card_balance_limit = formatCurrencyInput(this.$refs.card_balance_limit.getRawValue())
            }

            if (form.card_balance_limit !== null) {
                form.card_balance_limit = formatCurrencyInput(this.$refs.card_balance_limit.getRawValue())
            }

            if (form.online_redeem_limit !== null) {
                form.online_redeem_limit = formatCurrencyInput(this.$refs.online_redeem_limit.getRawValue())
            }

            if (form.card_redeem_limit !== null) {
                form.card_redeem_limit = formatCurrencyInput(this.$refs.card_redeem_limit.getRawValue())
            }

            if (form.card_issue_limit !== null) {
                form.card_issue_limit = formatCurrencyInput(this.$refs.card_issue_limit.getRawValue())
            }

            if (form.giftcard_max !== null) {
                form.giftcard_max = formatCurrencyInput(this.$refs.giftcard_max.getRawValue())
            }

            if (form.order_max !== null) {
                form.order_max = formatCurrencyInput(this.$refs.order_max.getRawValue())
            }

            return form
        }
    },
}
</script>

<style scoped>

</style>
