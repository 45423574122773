<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">

                    <div class="popup-title">
                        <h1 class="title is-1">
                            {{ $t('developers.api.form.title') }}
                        </h1>

                        <div class="popup-close" @click.prevent="destroy">
                            <i class="icon">close</i>
                        </div>
                    </div>

                    <g-form :form="form"
                            :action="`/api/tokens`"
                            @success="success($event)"
                            class="options-form">
                        <div class="popup-body popup-rows">
                            <div class="columns">
                                <div class="column is-one-third">
                                    <label>{{ $t('developers.api.form.name') }}</label>
                                </div>

                                <div class="column">
                                    <div class="field-body">
                                        <g-input name="name" :label="$t('developers.webhooks.form.name')"/>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-third">
                                    <label>{{ $t('developers.api.form.type') }}</label>
                                </div>

                                <div class="column">
                                    <div class="field-body">
                                        <g-select name="type" id="type" :options="typeOptions"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="popup-footer">
                            <a @click.prevent="destroy" href="#" role="button">Annuleren</a>
                            <g-button :disabled="this.isEmpty(form.name)">Opslaan</g-button>
                        </div>
                    </g-form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    data() {
        return {
            form:    new Form({
                name:   '',
                type: 'register',
                scopes: [
                    'giftcard-view',
                    'giftcard-issue',
                    'giftcard-redeem'
                ],
            }),
            typeOptions: [
                {
                    name: this.$t('developers.api.type_options.register'),
                    value: 'register',
                },
                {
                    name: this.$t('developers.api.type_options.ecommerce'),
                    value: 'ecommerce',
                },
                {
                    name: this.$t('developers.api.type_options.reservation'),
                    value: 'reservation',
                },
                {
                    name: this.$t('developers.api.type_options.accounting'),
                    value: 'accounting',
                },
            ],
            popup:    false
        }
    },

    props: {
        webhookId: {
            type: String,
            required: false,
        },
    },

    methods: {
        success (data) {
            this.$emit('created-application', data.token)
            this.destroy()

            this.form.name = ''
            this.popup = this.$popup('InformationalCodeSnippet', {
                title:       this.$t('developers.api.created_popup.title'),
                text:        this.$t('developers.api.created_popup.message'),
                codeSnippet: data.accessToken
            })
        },
        isEmpty (value) {
            return (value.length === 0 || !value.trim())
        },
    }
}
</script>
